/* eslint-disable global-require */
export default [
    {
        hasNativeSupport: () => typeof Array.from === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es6.array.from'));
            }, null, 'polyfills/array-from');
        },
    },
    {
        hasNativeSupport: () => typeof Array.of === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es6.array.of'));
            }, null, 'polyfills/array-of');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.includes === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es7.array.includes'));
            }, null, 'polyfills/array-includes');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.find === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es6.array.find'));
            }, null, 'polyfills/array-find');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.findIndex === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es6.array.find-index'));
            }, null, 'polyfills/array-find-index');
        },
    },
    {
        hasNativeSupport: () => typeof Array.prototype.fill === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es6.array.fill'));
            }, null, 'polyfills/array-fill');
        },
    },
    {
        hasNativeSupport: () => typeof Object.assign === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es6.object.assign'));
            }, null, 'polyfills/object-assign');
        },
    },
    {
        hasNativeSupport: () => typeof Object.entries === 'function',
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('core-js/modules/es7.object.entries'));
            }, null, 'polyfills/object-entries');
        },
    },
    {
        hasNativeSupport: () => !!window.URLSearchParams && (new window.URLSearchParams({ a: 1 })).toString() === 'a=1',
        polyfill(resolve) {
            require.ensure([], () => {
                window.URLSearchParams = require('url-search-params');
                resolve();
            }, null, 'polyfills/url-search-params');
        },
    },
    {
        hasNativeSupport: () => {
            return 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype && 'isIntersecting' in window.IntersectionObserverEntry.prototype;
        },
        polyfill(resolve) {
            require.ensure([], () => {
                resolve(require('intersection-observer'));
            }, null, 'polyfills/intersection-observer');
        },
    },
];
